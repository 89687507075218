import { createSlice } from '@reduxjs/toolkit';
import { BackendConfig } from '@wearemojo/api-client';
import { UIFlags } from '@wearemojo/ui-components';

import type { LogsConfig, LogType } from '../utils/logging';
import { AppState } from '.';

type SanityConfig = {
	projectId: string;
	dataset: string;
	useCdn: boolean;
};

const initialState: {
	backendConfigOverride?: BackendConfig;
	uiFlagOverrides: Partial<UIFlags>;
	debugFlags: DebugFlags;
	cmsContent: string | null;
	cmsContentAttribute: string | null;
	showStaffKnob: boolean;
	enabledLogs: LogsConfig;
	sanityConfig: SanityConfig | null;
} = {
	backendConfigOverride: undefined,
	uiFlagOverrides: {},
	debugFlags: {},
	cmsContent: null,
	cmsContentAttribute: null,
	showStaffKnob: false,
	enabledLogs: {
		analytics: __DEV__,
		info: __DEV__,
		warning: __DEV__,
		error: __DEV__,
	},
	sanityConfig: null,
};

const devSlice = createSlice({
	name: 'dev',
	initialState,
	reducers: {
		setBackendConfigOverride: (
			state,
			action: { payload: BackendConfig | undefined },
		) => {
			state.backendConfigOverride = action.payload;
		},
		setUiFlagOverrides: (state, action: { payload: Partial<UIFlags> }) => {
			state.uiFlagOverrides = action.payload;
		},
		toggleDebugFlag: (
			state,
			{ payload: flag }: { payload: keyof DebugFlags },
		) => {
			state.debugFlags[flag] = !state.debugFlags[flag];
		},
		setDevCmsContent: (state, action: { payload: string | null }) => {
			state.cmsContent = action.payload;
		},
		setDevCmsContentAttribute: (state, action: { payload: string | null }) => {
			state.cmsContentAttribute = action.payload;
		},
		setSanityConfig: (state, action: { payload: SanityConfig }) => {
			state.sanityConfig = action.payload;
		},
		showStaffKnob: (state) => {
			state.showStaffKnob = true;
		},
		toggleLogsEnabled: (state, action: { payload: LogType }) => {
			state.enabledLogs[action.payload] = !state.enabledLogs[action.payload];
		},
	},
});

export const {
	setBackendConfigOverride,
	setUiFlagOverrides,
	toggleDebugFlag,
	setDevCmsContent,
	setDevCmsContentAttribute,
	setSanityConfig,
	showStaffKnob,
	toggleLogsEnabled,
} = devSlice.actions;
export const selectBackendConfigOverride = (state: AppState) =>
	state.dev.backendConfigOverride;
export const selectUiFlagOverrides = (state: AppState) =>
	state.dev.uiFlagOverrides;
export const selectDebugFlags = (state: AppState) => state.dev.debugFlags;
export const selectDevCmsContent = (state: AppState) => state.dev.cmsContent;
export const selectDevCmsContentAttribute = (state: AppState) =>
	state.dev.cmsContentAttribute;
export const selectEnabledLogs = (state: AppState) => state.dev.enabledLogs;
export const selectSanityConfig = (state: AppState) => state.dev.sanityConfig;

export type DebugFlags = {
	// remove this and use specific fields instead
	[key: string]: boolean;
};

export default devSlice;
