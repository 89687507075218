import {
	CMS_IMAGE_WIDTH,
	CMSUtil,
	useSanityImageAttributes,
} from '@wearemojo/ui-components';
import { ReactNode, useCallback, useMemo } from 'react';

import useTransformActionToLinkProvider from '../hooks/cms/useTransformActionToLinkProvider';

const _CMSGlobalContextProvider = ({
	children,
	transformActionToActionLink,
}: {
	children: ReactNode;
	transformActionToActionLink: CMSUtil.CMSGlobalContextType['transformActionToActionLink'];
}) => {
	const imgAttr = useSanityImageAttributes(CMS_IMAGE_WIDTH);

	const context = useMemo<CMSUtil.CMSGlobalContextType>(
		() => ({
			imgAttr,
			transformActionToActionLink,
		}),
		[imgAttr, transformActionToActionLink],
	);
	return (
		<CMSUtil.CMSGlobalContext.Provider value={context}>
			{children}
		</CMSUtil.CMSGlobalContext.Provider>
	);
};

const CMSGlobalContextProvider = ({ children }: { children: ReactNode }) => {
	const transformActionToActionLink = useTransformActionToLinkProvider();

	return (
		<_CMSGlobalContextProvider
			transformActionToActionLink={transformActionToActionLink}
		>
			{children}
		</_CMSGlobalContextProvider>
	);
};

/**
 * Upper-level version of CMSGlobalContext that sits above ModalManager context
 * and provides access of `imgAttr` to it.
 * `transformActionToActionLink` can't be used inside Modals, so we throw an
 * error if that is ever attempted.
 */
export const CMSGlobalContextUIProvider = ({
	children,
}: {
	children: ReactNode;
}) => {
	const transformActionToActionLink = useCallback(() => {
		throw new Error(`Undefined transformActionToActionLink`);
	}, []);

	return (
		<_CMSGlobalContextProvider
			transformActionToActionLink={transformActionToActionLink}
		>
			{children}
		</_CMSGlobalContextProvider>
	);
};

export default CMSGlobalContextProvider;
