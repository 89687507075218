import { createSlice } from '@reduxjs/toolkit';
import { Service, ServiceParams } from '@wearemojo/api-client';

import { AppState } from '.';

export type EfficacyMeasurement = {
	answers: EfficacyMeasurementResponse[];
};

type EfficacyMeasurementResponse =
	ServiceParams[Service.efficacymeasurement]['submitAnswers']['responses'][number];

const initialState: EfficacyMeasurement = {
	answers: [],
};

const efficacymeasurementSlice = createSlice({
	name: 'efficacymeasurement',
	initialState,
	reducers: {
		clearEfficiencyMeasurementAnswers: (state) => {
			state.answers = [];
		},
		recordEfficiencyMeasurementAnswer: (
			state,
			action: {
				payload: {
					index: number;
				} & EfficacyMeasurementResponse;
			},
		) => {
			const { index, ...payload } = action.payload;
			state.answers[index] = payload;
		},
	},
});

export const {
	clearEfficiencyMeasurementAnswers,
	recordEfficiencyMeasurementAnswer,
} = efficacymeasurementSlice.actions;

export const selectEfficacyMeasurementAnswers = (state: AppState) =>
	state.efficacymeasurement.answers;

export default efficacymeasurementSlice;
