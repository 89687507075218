import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import useAppDispatch from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { AppState } from '.';

export enum OnceKey {
	CommunityExplainedShown = 'CommunityExplainedShown',
	FreeUserFirstCourseCompletedShown = 'FreeUserFirstCourseCompletedShown',
	HomeDayCompleteShown = 'HomeDayCompleteShown',
	InAppReviewRequested = 'InAppReviewRequested',
	SWScoreExplanationShown = 'SWScoreExplanationShown',
	WelcomeScreenDismissed = 'WelcomeScreenDismissed',
	StreaksCommitmentViewed = 'StreaksCommitmentViewed',
}

type OnceKeyOrString = OnceKey | string;

export const useOnce = (key: OnceKeyOrString) => {
	const dispatch = useAppDispatch();
	const isDone = useAppSelector(createSelectOnce(key));
	return useMemo(
		() => ({
			isDone,
			markDone: () => {
				if (key) {
					dispatch(markDone(key));
				}
			},
		}),
		[dispatch, isDone, key],
	);
};

export type Once = {
	done: Partial<Record<OnceKeyOrString, boolean>>;
};

const initialState: Once = {
	done: {},
};

const onceSlice = createSlice({
	name: 'once',
	initialState,
	reducers: {
		markDone: (
			state,
			action: {
				payload: OnceKeyOrString;
			},
		) => {
			state.done[action.payload] = true;
		},
		resetOnceData: (state) => {
			state.done = {};
		},
	},
});

export const selectOnce = (state: AppState) => state.once.done;
export const createSelectOnce = (key: OnceKeyOrString) => (state: AppState) =>
	(!!key && selectOnce(state)[key]) ?? false;

export const { markDone, resetOnceData } = onceSlice.actions;
export default onceSlice;
