/*
	@TODO: potentially merge these into ScreenKey if we decide we want to link to navigators without specifying a screen
	i.e. NavigatorKey.SoundNavigator -> ScreenKey.SoundNavigator

	Cavet: getToParams() needs to account for these (will likely need a refactor)
*/
enum NavigatorKey {
	RootNavigator = 'RootNavigator',
	MainNavigator = 'MainNavigator',
	ActivityNavigator = 'ActivityNavigator',
	ExploreNavigator = 'ExploreNavigator',
	SoundNavigator = 'SoundNavigator',
	DevNavigator = 'DevNavigator',
	AccountNavigator = 'AccountNavigator',
	CancelationNavigator = 'CancelationNavigator',
	CheckoutNavigator = 'CheckoutNavigator',
	CommunityNavigator = 'CommunityNavigator',
	StreaksNavigator = 'StreaksNavigator',
	SQScoreNavigator = 'SQScoreNavigator',
}

export default NavigatorKey;
