import { BLOCK_CONTENT_FRAGMENT, HELP_FRAGMENT } from './fragments';

export const FRAGMENTS_QUERY = `*[_type match "Fragment*"] {
	...,
	_type == 'FragmentCommunityExpertQuotes' => {
		expertQuotes[] {
			...,
			expert-> {
				...,
				image {
					asset->,
				},
			},
		},
	},
	_type == 'FragmentProgram' => {
		items[] {
			items[]-> {
				...,
				cardImage {
					asset->,
				},
				stagingContent {
					...,
					content[] {
						${BLOCK_CONTENT_FRAGMENT}
					},
				}
			},
		},
	},
	_type == 'FragmentStreaks' => {
		streaksCommitment {
			...,
			poll->,
		},
	},
	_type == 'FragmentSQScorePillars' => {
		...,
		scorePillars {
			...,
			connection_building {
				...,
				pillarImage { asset-> }, rewardImage { asset-> },
			},
			mental_resilience {
				...,
				pillarImage { asset-> }, rewardImage { asset-> },
			},
			sexual_fitness {
				...,
				pillarImage { asset-> }, rewardImage { asset-> },
			},
			stimulation_response {
				...,
				pillarImage { asset-> }, rewardImage { asset-> },
			},
		}
	},
	_type == 'FragmentRefer' => {
		...,
		title {
			_type == 'reference' => @-> {
				...,
			}
		},
		content {
			_type == 'reference' => @-> {
				...,
			}
		},
		referTitle {
			_type == 'reference' => @-> {
				...,
			}
		},
		discountText {
			_type == 'reference' => @-> {
				...,
			}
		},
	},
	_type == 'FragmentCheckoutSubscriptionPlans' => {
		...,
		beforeYouGo {
			...,
			title->,
			content->,
		},
	},
	_type == 'FragmentExplore' => {
		...,
		lockedModal {
			...,
			content->,
		},
	},
	_type == 'FragmentOneActivityADay' => {
		...,
		getAnotherActivityContent {
			...,
			description->,
		},
		completedActivityContent {
			...,
			description->,
		},
	},
	_type == 'FragmentCelebrateCheckout' => {
		...,
		content->,
	},
}`;

export const ERROR_MESSAGES_QUERY = `*[_id == "singleton-ErrorMessages"][0]`;

export const SOUND_SERIES_QUERY = `*[_type == "SoundSeries"] {
	...,
	episodes[]-> {
		...,
		audio-> {
			...,
			file {
				asset->
			},
		}
	}
}`;

export const MEDITATIONS_QUERY = `*[_type == "Meditation"] {
	...,
	series[]-> {
		...,
		episodes[]->
	}
}[0]`;

export const POLL_QUERY = `*[_type == "Poll"] {
	...,
	options[] {
		...,
		_type == 'PollOption' => {
			...,
			text,
		},
	},
}`;

export const THERAPY_SESSIONS_QUERY = `*[_type == "TherapySession"] {
	...,
	series[]-> {
		...,
		episodes[]->
	}
}[0]`;

export const AUDIO_PORN_QUERY = `*[_type == "AudioPorn"] {
	...,
	series[]-> {
		...,
		episodes[]->
	}
}[0]`;

export const EFFICACY_QUESTION_SET_QUERY =
	'*[_type == "EfficacyQuestionSetVersion" && version == $version][0]';

export const FLOW_DOCUMENTS_QUERY = `
	*[
		_type == 'Flow'
		|| _type == 'FlowGroup'
		|| _type match 'Flow*Step'
		|| _type match 'Expression*'
	] {
		...,
		content[] {
			${BLOCK_CONTENT_FRAGMENT}
		},
		contentAppend[] {
			${BLOCK_CONTENT_FRAGMENT}
		},
		footerContent[] {
			${BLOCK_CONTENT_FRAGMENT}
		},
		_type == 'FlowPollStep' => {
			poll->,
		},
		_type == 'FlowPollQuestionAnswerStep' => {
			poll->,
			selectedPollContent,
		},
		_type == 'FlowNotificationsPreferenceStep' => {
			image {
				asset->,
			},
		},
		_type == 'FlowExerciseTimedImageStep' => {
			image {
				asset->,
			},
		},
		_type == 'FlowExerciseRESupportStep' => {
			pages[] {
				...,
				content[] {
					${BLOCK_CONTENT_FRAGMENT}
				},
				lottieFile {
					asset->,
				},
			},
		},
		_type == 'ExpressionPollAnswerKeys' => {
			poll-> {
				id,
			},
		},
		_type == 'FlowAudioPlayerStep' => {
			...,
			audio-> {
				...,
				file {
					asset->,
				},
			},
			playerImage {
				asset->,
			}
		},
		_type in [
			'ExpressionProgramActivityCompleted',
			'ExpressionProgramActivityNotCompleted',
		] => {
			...,
			filters[] {
				...,
				_type == 'ProgramActivityFilterExactActivity' => {
					activity-> {
						id,
					},
				},
			},
		},
		_type == 'FlowExercisePelvicFloorSqueezeStep' => {
			squeezeTest {
				...,
				pages[] {
					...,
					content[] {
						${BLOCK_CONTENT_FRAGMENT}
					},
				},
			},
			squeezeWorkout {
				...,
				pages[] {
					...,
					content[] {
						${BLOCK_CONTENT_FRAGMENT}
					},
				},
			},
		},
		_type == 'FlowPelvicExerciseStep' => {
			...,
			backgroundAudio {
				asset->,
			},
			rest {
				...,
				audio {
					asset->,
				},
			},
			squeeze {
				...,
				audio {
					asset->,
				},
			},
		},
		overpanelHelpSection {
			${HELP_FRAGMENT}
		},
	}
`;
