import { createSlice } from '@reduxjs/toolkit';

import { AppState } from '.';

export type navigation = {
	isMobileNavOpen: boolean;
	isShowingWelcomeScreen: boolean;
};

const initialState: navigation = {
	isMobileNavOpen: false,
	isShowingWelcomeScreen: false,
};

const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		setIsMobileNavOpen: (state, action: { payload: boolean }) => {
			state.isMobileNavOpen = action.payload;
		},
		setIsShowingWelcomeScreen: (state, action: { payload: boolean }) => {
			state.isShowingWelcomeScreen = action.payload;
		},
	},
});
export const { setIsMobileNavOpen, setIsShowingWelcomeScreen } =
	navigationSlice.actions;
export const selectIsMobileNavOpen = (state: AppState) =>
	state.navigation.isMobileNavOpen;
export const selectIsShowingWelcomeScreen = (state: AppState) =>
	state.navigation.isShowingWelcomeScreen;

export default navigationSlice;
