import { createSlice } from '@reduxjs/toolkit';

import { AppState } from '.';

export type SessionState = {
	userId: string | undefined;
	impersonatedUserId: string | undefined;
	sessionId: string | undefined;
};

const initialState: SessionState = {
	// Values from Mojo API Client
	userId: undefined,
	impersonatedUserId: undefined,
	sessionId: undefined,
};

const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setUserId: (state, action: { payload: string | undefined }) => {
			state.userId = action.payload;

			if (!action.payload) {
				state.impersonatedUserId = undefined;
			}
		},
		setImpersonatedUserId: (state, action: { payload: string | undefined }) => {
			state.impersonatedUserId = action.payload;
		},
		setSessionId: (state, action: { payload: string | undefined }) => {
			state.sessionId = action.payload;
		},
	},
});

export const { setUserId, setImpersonatedUserId, setSessionId } =
	sessionSlice.actions;
export const selectUserId = (state: AppState) =>
	state.session.impersonatedUserId ?? state.session.userId;
export const selectImpersonatedUserId = (state: AppState) =>
	state.session.impersonatedUserId;
export const selectOriginalUserId = (state: AppState) => state.session.userId;
export const selectSessionId = (state: AppState) => state.session.sessionId;
export const selectIsLoggedIn = (state: AppState) => !!state.session.userId;

export default sessionSlice;
